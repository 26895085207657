import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";
import CurrentCallDisplay from "../current-call-display/current-call-display.component.jsx";
import { CurrentCallContext } from "../../contexts/current-call.context";
import { NumberToDialContext } from "../../contexts/number-to-dial.context";
import { PhoneStatusContext } from "../../contexts/phone-status.context";
import { DefaultDeviceContext } from "../../contexts/default-device.context.jsx";
import { SettingsContext } from "../../contexts/settings.context.jsx";
import { useAudioContext } from "../../contexts/audioContext.context.jsx";
import { normalizeNumber, formatE164 } from "../../utils/numberUtils.js";
import { useSIP } from "../../contexts/SIPContext";

import "./phone.styles.scss";

const keys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"];

function Phone({ onKeyPress }) {
  const [display, setDisplay] = useState("");
  const { numberToDial, setNumberToDial } = useContext(NumberToDialContext);
  const { defaultDevice } = useContext(DefaultDeviceContext);
  const { currentCall, setCurrentCall } = useContext(CurrentCallContext);
  const {
    isOnCall,
    // setIsOnCall
  } = useContext(PhoneStatusContext);
  const { keysMuted } = useContext(SettingsContext);
  const { playDTMFTone } = useAudioContext();
  const { makeCall, sendDTMF } = useSIP();
  const textFieldRef = useRef(null);

  useEffect(() => {
    const formattedNumber = formatE164(normalizeNumber(numberToDial));
    setDisplay(formattedNumber);
  }, [numberToDial]);

  const setPhoneOnCall = () => {
    const normalizedNumber = normalizeNumber(numberToDial); // Normalize the number

    setCurrentCall({
      display_name: normalizedNumber, // Use the normalized number
      display_number: normalizedNumber, // Use the normalized number
      call_direction: "outbound",
    });

    makeCall(
      normalizedNumber.includes("+")
        ? normalizedNumber
        : `sip:${normalizedNumber}@${defaultDevice.realm}`
    );

    // setIsOnCall(true);
  };

  const handleKeyPress = (digit) => {
    if (!keysMuted) playDTMFTone(digit);
    !isOnCall
      ? setNumberToDial(normalizeNumber(numberToDial + digit))
      : sendDTMF(digit);
    // setNumberToDial(numberToDial + digit);
  };

  const handleDelete = () => {
    setNumberToDial((numberToDial) => numberToDial.slice(0, -1));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setPhoneOnCall();
    } else if (event.key === "Backspace") {
      handleDelete();
    } else if (event.key === "+" || event.key === "*") {
      setNumberToDial((prev) => prev + event.key);
    } else if (!isNaN(parseInt(event.key))) {
      if (!keysMuted) playDTMFTone(event.key);
      // Only capture number keys
      setNumberToDial((prev) => prev + event.key);
    }
  };

  const handlePaste = (event) => {
    const paste = (event.clipboardData || window.clipboardData).getData("text");
    const normalizedPaste = normalizeNumber(paste); // Normalize the pasted number if needed
    // Update the numberToDial here
    setNumberToDial(normalizedPaste);
    event.preventDefault(); // Prevent the default paste behavior
  };

  return (
    <Box>
      {isOnCall ? (
        <CurrentCallDisplay
          isOpen={isOnCall}
          callerName={currentCall.display_name}
          callerID={currentCall.display_number}
        />
      ) : (
        ""
      )}
      {/* <CurrentCallDisplay
        isOpen={isOnCall}
        // callerName={currentCall.display_name}
        // callerID={currentCall.display_number}
      /> */}
      <TextField
        fullWidth
        inputRef={textFieldRef}
        value={display}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        variant="standard"
        InputProps={{
          style: { textAlign: "center" },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleDelete} edge="end">
                <BackspaceIcon sx={{ transform: "scale(0.9)" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ width: "100%", marginBottom: 3 }}
      />
      <Box className="dialpad-container">
        {keys.map((key, index) => (
          <Button
            className="keypad-button"
            key={index}
            variant="contained"
            onClick={() => handleKeyPress(key)}
          >
            {key}
          </Button>
        ))}
      </Box>
    </Box>
  );
}

export default Phone;
